const theme = {
    hatchedBackground:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIElEQVQYV2P8////fwYGBgZGRkZGMI0hABIFAbgEugAAQFQP/QfjEPcAAAAASUVORK5CYII=)',
  
    // background: '#CCF5AC',
    // background: '#416165',
    background: '#4C6663',
  
    canvas: '#c7c7df',
    material: '#a1a3ca',
    materialDark: '#6063a5',
  
    borderDarkest: '#010601',
    borderLightest: '#c7c7df',
    borderDark: '#5b57a1',
    borderLight: '#a4a7c8',
  
    headerMaterialDark: '#000000',
    headerMaterialLight: '#000000',
    headerText: '#010601',
  
    text: '#010601',
    textInvert: '#bfff00',
    textDisabled: '#5b57a1',
    textDisabledShadow: '#c7c7df',
  
    inputText: '#050608',
    inputTextInvert: '#ffffff',
    inputTextDisabled: '#888c8f',
    inputTextDisabledShadow: '#ffffff',
  
    tooltip: '#fefbcc',
  
    anchor: '#8d88c2',
    anchorVisited: '#440381',
  
    hoverBackground: '#8d88c2',
    checkmark: '#010601',
    progress: '#8d88c2',
  
    flatLight: '#a4a7c8',
    flatDark: '#5b57a1',
  };
  
  export default theme;