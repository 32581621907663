import React, { Component } from "react";
import Typist from "react-typist";

import agentImg from "../../resources/images/the_agent.gif";
import agentImgShut from "../../resources/images/the_agent_shut.gif";

import "animate.css";
import "./TheAgent.css";

class TheAgent extends Component {
  state = {
    stillTalking: true,
    imageLoaded: false,
  };

  closeMouth = () => {
    this.setState({ stillTalking: false });
  };

  imageLoaded = () => {
    this.setState({ imageLoaded: true });
  };

  renderSpeechBubble = (finalSpeechText) => {
    const { stillTalking, imageLoaded } = this.state;

    if (!imageLoaded) {
      return null;
    }

    return (
      <div className="agent-speech animated fadeIn">
        {stillTalking ? (
          <Typist
            avgTypingDelay={25}
            cursor={{ show: false }}
            onTypingDone={this.closeMouth}
          >
            {finalSpeechText}
          </Typist>
        ) : (
          <span>{finalSpeechText}</span>
        )}
      </div>
    );
  };

  render() {
    const { stillTalking } = this.state;
    const { displayAgent } = this.props;

    const speechTextBeforeBug = (
      <span>السلام عليكم ورحمة الله وبركاته, بارك الله فيكم</span>
    );

    const speechTextAfterBug = <span>I'm really happy right now!</span>;

    const finalSpeechText = localStorage.getItem("fixed")
      ? speechTextAfterBug
      : speechTextBeforeBug;

    if (!displayAgent) {
      return null;
    }

    return (
      <div className="agent-container">
        <div className="agent-image">
          <img
            src={stillTalking ? agentImg : agentImgShut}
            onLoad={this.imageLoaded}
            style={{ height: "250px" }}
            alt="the secret agent"
          />
        </div>
        {this.renderSpeechBubble(finalSpeechText)}
      </div>
    );
  }
}

export default TheAgent;
