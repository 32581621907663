import React, { Component } from 'react';
import {
  Button, Anchor, Tooltip, Progress, Hourglass
} from 'react95';

import contactIcon from '../../resources/icons/contact.gif';

import githubIcon from '../../resources/icons/social/github.gif';
import dribbbleIcon from '../../resources/icons/social/dribbble.gif';
import keybaseIcon from '../../resources/icons/social/keybase.gif';
import twitterIcon from '../../resources/icons/social/twitter.gif';
import emailIcon from '../../resources/icons/social/email.gif';
import pgpIcon from '../../resources/icons/social/pgp.png';
import linkedinIcon from '../../resources/icons/social/linkedin.gif';

import pgpKey from '../../resources/misc/public.asc';

import './Contact.css';

class ContactHeader extends Component {
  render = () => (
    <span>
      <img src={ contactIcon } alt='icon' style={ { height: '15px' } }/> Contact
    </span>
  )
}

class ContactBody extends Component {
  render = () => (<div>
    <span>
      Feel free to stalk me on the web or drop me an email.
    </span>
    <div className='social-icons-container'>
      <Tooltip text='Email'>
        <Anchor href='mailto:fauzanlubis23@gmail.com?subject=Hi%20Fauzan%20Awesome' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ emailIcon } style={ { height: '40px' } } alt="email"/>
          </Button>
        </Anchor>
      </Tooltip>
   
      <Tooltip text='GitHub'>
        <Anchor href='https://github.com/fauzanlbs' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ githubIcon } style={ { height: '40px' } } alt="github"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='LinkedIn'>
        <Anchor href='https://www.linkedin.com/in/fauzan-lubis-95632794' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ linkedinIcon } style={ { height: '30px' } } alt="linkedin"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='Medium'>
        <Anchor href='https://medium.com/@fauzanlubis23' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ keybaseIcon } style={ { height: '30px' } } alt="medium"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='Twitter'>
        <Anchor href='https://twitter.com/fauzan_lbs' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ twitterIcon } style={ { height: '30px' } } alt="twitter"/>
          </Button>
        </Anchor>
      </Tooltip>
    </div>
    <div className='social-icons-container'>
    <Progress percent={53} />
    </div>
    <div className='social-icons-container'>
    <Hourglass />
    </div>
    <div className='social-icons-container'>
    <Tooltip text="Nothing really 🤷‍">
      <Button>Whad do I do?</Button>
    </Tooltip>
    </div>
   
  </div>)
}

export { ContactHeader, ContactBody };
