import React, { Component } from 'react';
import { Button, Cutout } from 'react95';

import './Jancuk.css';
import 'animate.css';

import jancukMessages from '../../resources/jancuk-messages.json';

import jancukIcon from '../../resources/icons/trash.gif';
import spiderWindowIcon from '../../resources/icons/spiderwindow.gif';

class JancukHeader extends Component {
  render = () => (
    <span>
      <img src={ jancukIcon } alt='main icon' style={ { height: '15px' } }/> Random
    </span>
  )
}

class JancukBody extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem('messageCounter') === null) {
      sessionStorage.setItem('messageCounter', 0);
    }
  }

  increaseClickCount = () => {
    const { closeWindow } = this.props;
    const messageCounter = parseInt(sessionStorage.getItem('messageCounter'), 10);

    let newMessageCounter = messageCounter;
    if (messageCounter + 1 < jancukMessages.length) {
      newMessageCounter += 1;
    }

    if (messageCounter === jancukMessages.length - 1) {
      sessionStorage.setItem('eggTriggered', true);
    } else {
      sessionStorage.setItem('messageCounter', newMessageCounter);
    }

    closeWindow();
  }

  render = () => {
    const messageCounter = parseInt(sessionStorage.getItem('messageCounter'), 10);
    const currentMessage = jancukMessages[messageCounter].message;
    const eggTriggered = sessionStorage.getItem('eggTriggered') === 'true';

    return (<div>
      <div className='jancuk-message-container'>
        <span className='jancuk-message-text'>
          <img src={ spiderWindowIcon } alt='spider window icon' /><span style={ { margin: '15px' } }>{currentMessage.charAt(0).toUpperCase() + currentMessage.slice(1)}</span>
        </span>
      </div>
      <div className='action-button-container'>
        <Cutout>
          <div>
            <Button
              fullWidth
              onClick={ this.increaseClickCount }
              disabled={ eggTriggered }
              style={ { width: '150px' } }
            >{jancukMessages[messageCounter].button}</Button>
          </div>
        </Cutout>
      </div>
    </div>);
  }
}

export { JancukHeader, JancukBody };
