import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';

// i love sentry, because it is free
// Sentry.init({dsn: "https://a583cb7b959d445cba9614bd311c1b01@sentry.io/1990113"});

// import * as serviceWorker from './serviceWorker';

console.info(`
  << 🥏 >>

 ⊂  .@.  ⊃
  \\ (°° /
   \\|T|/
    |=|
    / \\
  _/   \\_

     ~ Hi I'm Tron
       and I fight for the users! ~
`);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
