import React, { Component } from 'react';
import moment from 'moment';

import aboutIcon from '../../resources/icons/about.gif';
import avatarPicture from '../../resources/images/myavatar.gif';

import './About.css';

class AboutHeader extends Component {
  render = () => (
    <span>
      <img src={ aboutIcon } alt='about' style={ { height: '15px' } }/> About
    </span>
  )
}

class AboutBody extends Component {
  state = {
    timePassed: '',
  };

  getDateDiff = () => {
    const now = moment();
    const then = moment([2016, 8, 15]);
    const years = now.diff(then, 'year');

    const timePassed = `${years} years`;
    this.setState({ timePassed });
  }

  componentDidMount() {
    this.getDateDiff();
  }

  render = () => {
    const { timePassed } = this.state;
    return (
      <div className='text-container'>
        <img src={ avatarPicture } alt='my avatar' className='avatar-picture' />
        <span style={ { fontWeight: 'bold', fontSize: '1.5em' } }>Hello there!</span>
        <p>
         
          on the internet my nick name is usually <b></b>.
        </p>
        <p>
          I was born in <a href='https://en.wikipedia.org/wiki/Lamongan_Regency' target='_blank' rel='noopener noreferrer'>Lamongan (Indonesia)</a> <span role="img" aria-label="indonesian-flag">🇮🇩</span> where I lived most of my life. I moved to Pekanbaru Indonesia <span role="img" aria-label="indonesian-flag">🇮🇩</span> { timePassed } ago where I currently work as a full stack software engineer.
          I'm just another college dropout from <a href='http://umri.ac.id/home/lang/en' target='_blank' rel='noopener noreferrer'>Muhammadiyah University of Riau</a>.
        </p>
        <p>
          In my free time I like to practice freestyle bmx and cycling around
          the streets of Pekanbaru on my <a href='https://harobikes.com/collections/bmx/products/interstate-2020' target='_blank' rel='noopener noreferrer'>Sick Bike</a>.
          Being Indonesian such a great hiking location I enjoy exploring the
          countryside and hike the amazing Merapi mountains <span role="img" aria-label="mountain">⛰</span>
        </p>
        <p>
          I'm passionate about retrocomputing,
          when I get the chance I collect old computers from the
          80s and I'm really fond of skeuomorphic design of 90s and early 2000s UIs.
        </p>
      </div>
    );
  }
}

export { AboutHeader, AboutBody };
